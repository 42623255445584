<!--
 * @Description: 答卷详情
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-12 07:31:17
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-14 16:29:45
-->
<template>
  <div class="questionnaireDetail" v-if="questionnaire.questionnaireInfo">
    <div class="content">
      <div class="left-content"></div>
      <div class="questionnaire">
        <div class="title">
          <h1>{{questionnaire.questionnaireProtoInfo.title}}</h1>
          <div class="header">
            <p>答卷编号: <span>{{questionnaire.questionnaireInfo.numId}}</span></p>
            <p>IP所在地区: <span>{{questionnaire.questionnaireInfo.ipLocation}}</span></p>
            <p>回收渠道：<span>{{questionnaire.questionnaireInfo.collectSource}}</span></p>
          </div>
          <div class="header">
            <p>答案所用时间: <span>{{questionnaire.questionnaireInfo.duration}}秒</span></p>
            <p>答案提交时间: <span>{{questionnaire.questionnaireInfo.createTime}}</span></p>
          </div>
          <div>备注: {{questionnaire.questionnaireInfo.remark}}</div>
        </div>
        <div v-for="(item, index) in list" :key="item.questionId">
          <component
            v-if="!item.answer || Object.keys(item.answer).length > 0"
            :is="item.componentName"
            :item="item"
            :index="index"
            :isOnlyRead="true"
            :isDisabled="true"
            :isPc="true"
          />
        </div>
      </div>
      <div class="right-content">
        <div @click="handleCloseClick" class="iconfont close">&#xe61c;</div>
        <div class="nav">
          <h1 class="nav-title">操作</h1>
          <ul>
            <li 
              @click="handleInvalidClick" 
              v-if="questionnaire.questionnaireInfo.status === 'VALID'"
            >
              无效答卷
            </li>
            <li @click="handleRemarkClick">备注</li>
            <li @click="handleNextClick(questionnaire.previousQuestionnaireId, 'up')">上一份</li>
            <li @click="handleNextClick(questionnaire.nextQuestionnaireId, 'down')">下一份</li>
            <li>
              <a-input 
                class="num" 
                type="number"
                v-model:value="answerIndex"
                @keyup.enter="handleJump(questionnaire.questionnaireProtoInfo.id, answerIndex, questionnaire.questionnaireInfo.status)"
                @change="handleIndexChange"
              />/&nbsp;&nbsp;&nbsp;{{questionnaire.total}}
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>

  <RemarkModal
    v-if="questionnaire.questionnaireInfo"
    :visible="isShowRemarkModal"
    :text="questionnaire.questionnaireInfo.remark || ''"
    :iscallback = "iscallback"
    title="答卷备注"
    inputTitle="答卷备注"
    placeholder="选填"
    @finishEditText="handleFinishEditText"
  />
</template>
<script>
import RemarkModal from '@/components/modal/RemarkModal'
import Part from '@/components/question/others/Part'
import SingleChoice from '@/components/question/SingleChoice'
import MultipleChoice from '@/components/question/MultipleChoice'
import DropDown from '@/components/question/DropDown'
import Scale from '@/components/question/Scale'
import MatrixScale from '@/components/question/MatrixScale'
import MatrixSingleChoice from '@/components/question/MatrixSingleChoice'
import MatrixMultipleChoice from '@/components/question/MatrixMultipleChoice'
import Short from '@/components/question/Short'
import Date from '@/components/question/Date'

import useCommonConstructEffect from '@/effects/constructEffect'
import globalQuestionnaire from '@/class/useQuestionnaire'

import { computed, reactive, watchEffect, ref, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { optionQuestionnaire, getQuestionnaireWithAnswer } from '@/api/questionnaire'
import { message } from 'ant-design-vue'

/**
 * 获取答卷内容
 * @param {string} questionnaireId 答卷id  or questionnaireProtoId 问卷原型id
 * @param {number} index 答卷序号
 * @param {string} status 答卷状态（INVALID/VALID）
 */
const getQuestionnaireWithAnswerEffect = async (...params) => {
  const { constructQuestWithAnswer } = useCommonConstructEffect()
  const result = await getQuestionnaireWithAnswer({
    ...params[0]
  })
  if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
    const data = result.data.data
    data.partMap =data.questionnaireProtoInfo.partMap
    data.pageMap =data.questionnaireProtoInfo.pageMap
    globalQuestionnaire.setQuestionnaire(constructQuestWithAnswer(data))
  } else {
    message.error(' 请求答卷失败，失败原因：' + result.data.msg)
  }
}

/**
 * 根据id获取答卷内容
 * @param {string} questionnaireId 答卷id
 */
const getQuestionnaireEffect = (questionnaireId) => {
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const list = ref([])
  const answerIndex = ref()
  const { constructQuestWithAnswer } = useCommonConstructEffect()
  
  getQuestionnaireWithAnswerEffect({
    questionnaireId
  })
  
  watchEffect(() => {
    list.value = []
    let questions = questionnaire.questions || []
    for (let i = 0; i < questions.length; i++) {
      if (!/(page)/.test(questions[i].questionType.toLowerCase())) {
        list.value.push(questions[i])
      }
    }
  })
  return {
    questionnaire,
    answerIndex,
    list,
  }
}

/**
 * 控制面板相关操作
 */
const navEffect = (questionnaire, list, answerIndex, props, emit, constructQuestWithAnswer) => {
  const isShowRemarkModal = ref(false)
  const iscallback = ref(false)
  // 答卷备注
  const handleRemarkClick = () => {
    isShowRemarkModal.value = true
    iscallback.value = false

  }
  // 无效问卷
  const handleInvalidClick = () => {
    // 1、弹出框，写备注
    // 2、点击确定，将答卷变为有效
    // 3、获取当前答卷的下一页id
    //  3.1、如果没有下一页，就关闭查看答卷页面
    //  3.2、如果有下一页，获取当前下一页id，根据id查询数据，更新数据
    // 
    isShowRemarkModal.value = true
    iscallback.value = true
  }
  // 处理备注弹出框确认事件
  const handleFinishEditText = async (text, isCallback, isCancel) => {
    isShowRemarkModal.value = false
    if (isCancel) return
    questionnaire.questionnaireInfo.remark = text || ''
    const id = questionnaire.questionnaireInfo.id
    const option = 'REMARK'
    const res = await optionQuestionnaire(id, option, text)
    if (res.status === 200 && res.data?.code === '00000') {
      message.success('备注成功')
      if (isCallback) {
        // 1、标记问卷无效
        const res1 = await optionQuestionnaire(id, 'MARK_INVALID')
        if (res1.status === 200 && res1.data?.code === '00000') {
          message.success('已标注问卷无效')
          // 2、自动跳到下一页
        handleNextClick(questionnaire.nextQuestionnaireId)
        } else {
          message.error('标注问卷无效失败，失败原因：' + res.data.msg)
        }
      }
    } else {
      message.error('备注失败，失败原因：' + res.data.msg)
    }
  }
  // 上、下一页
  const handleNextClick = (questionnaireId, type) => {
    if (questionnaireId === null) {
      if (type === undefined) {
        emit('close')
        return 
      }
      const tips = type === "up" ? '第一页' : '最后一页'
      message.success(`本答卷已经是${tips}`)
      return
    }    
    getQuestionnaireWithAnswerEffect({
      questionnaireId
    })
  }
  // 答卷跳转事件
  const handleJump = async (questionnaireProtoId, offset, status) => {
    getQuestionnaireWithAnswerEffect({ questionnaireProtoId, offset, status })
  }
  // 监听问卷下标输入框
  const handleIndexChange = () => {
    // 只能输入正整数，如果超过最大下标输入，则直接改为最大下标
    const inputStr = answerIndex.value.trim()
    if (!inputStr) return
    if (!/^\d+$/g.test(inputStr)) {
      answerIndex.value = questionnaire.index
    } else {
      answerIndex.value = inputStr > questionnaire.total ? questionnaire.index : inputStr
    }
    
  }
  return {
    iscallback,
    isShowRemarkModal,
    handleRemarkClick,
    handleInvalidClick,
    handleNextClick,
    handleJump,
    handleIndexChange,
    handleFinishEditText,
  }
}

/**
 * emit相关事件
 */
const handleEmitEffect = (props, emit) => {
  const handleCloseClick = () => { emit('close') }
  return {
    handleCloseClick,
  }
}

export default {
  name: 'QuestionnaireDetail',
  props: ['questionnaireId'],
  emits: ['close'],
  components: {
    RemarkModal,
    Part,
    SingleChoice,
    MultipleChoice,
    DropDown,
    Scale,
    MatrixScale,
    MatrixSingleChoice,
    MatrixMultipleChoice,
    Short,
    Date,
  },
  setup (props, { emit }) {
    const { questionnaire, list, answerIndex } = getQuestionnaireEffect(props.questionnaireId)
    const { handleCloseClick } = handleEmitEffect(props, emit)
    const { constructQuestWithAnswer } = useCommonConstructEffect()
    const { isShowRemarkModal, iscallback, handleRemarkClick,
      handleInvalidClick, handleNextClick, handleJump, handleIndexChange,
      handleFinishEditText} = navEffect(questionnaire, list, answerIndex, props, emit, constructQuestWithAnswer)
    onUnmounted(() => {
      globalQuestionnaire.clearQuestionnaire()
    })
    return {
      answerIndex,
      list,
      questionnaire,

      handleCloseClick,
      
      isShowRemarkModal,
      iscallback,
      handleRemarkClick, 
      handleInvalidClick, 
      handleNextClick,
      handleJump,
      handleIndexChange,
      handleFinishEditText,
    }
  }
}
</script>
<style lang='less' scoped>
.questionnaireDetail {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 128px 300px 60px 300px;
  overflow: auto;
  background-color: @backgroundColor;
}
.iconfont {
  // position: fixed;
  // z-index: 99;
  // top: 10px;
  // right: 40px;
  font-size: 30px;
  cursor:pointer;
  color: #00A0E9;
}
.close {
  position: relative;
  text-align: right;
  top: -50px;
}
.content {
  display: flex;
}
.questionnaire {
  width: 1300px;
}
.left-content, .right-content {
  flex: 1;
}
.right-content {
  position: relative;
  top: -30px;
  padding-left: 50px;
}
.nav {
  width: 204px;
  height: 359px;
  // position: fixed;
  // z-index: 99;
  // top: 80px;
  // right: 110px;
  // width: 160px;
  // height: 260px;
  box-shadow: 0px 0px 4px 0px #dfdfdf;
  border-radius: 6px;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  font-size: 14px;
  color: #626262;
  h1 {
    margin: 20px 0 10px 0;
    font-size: 16px;
    text-align: center;
  }
  ul {
    li {
      margin: 8px 10px;
      text-align: center;
      height: 35px;
      line-height: 35px;
      border-radius: 4px;
      border: 1px solid #E1E1E8;
      cursor:pointer;
      &:hover {
        background-color: #00a0e91c;
      }
      &:last-child {
        border: none;
        font-weight: bold;
         &:hover {
          background-color: #fff;
        }
      }
    }
    .num {
      display: inline-block;
      padding: 10px;
      border: 1px solid #d9d9d9;
      width: 72px;
      height: 32px;
      margin-right: 10px;
      font-weight: bold;
    }
  }
}
.title {
  border-top: 4px solid #f59a23;
  padding: 50px;
  margin-bottom: 16px;
  background-color: #fff;
  h1 {
    font-size: 26px;
    font-weight: bold;
    margin: 0 0 24px 0;
  }
  .header {
    display: flex;
    p {
      width: 324px;
      margin-bottom: 16px;
      span {
        color: #7F7F88;
      }
    }
  }
  .ant-row {
    margin-bottom: 16px;
    font-size: 16px;
  }
}
</style>