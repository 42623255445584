<!--
 * @Description: 备注弹出框
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-14 14:15:48
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-14 10:27:19
-->
<template>
  <div>
    <a-modal 
      v-model:visible="visible" 
      :title="title" 
      @ok="handleOk"
      @cancel="handleClose"
      class="titleDescModal"
      width="1046px"
      :closable="false"
    >
      <span class="iconfont close" @click="handleClose">&#xe61c;</span>
      <div class="main">
        <div style="margin-right: 10px">{{inputTitle}}</div>
        <a-textarea v-model:value="text" placeholder="选填" allow-clear auto-size/>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { reactive, ref, watchEffect, toRefs } from 'vue'

const watchPropsEffect = (props) => {
  const modalInfo = reactive({
    visible: props.visible,
    title: props.title,
    inputTitle: props.inputTitle,
    text: props.text
  })
  watchEffect(() => {
    modalInfo.visible = props.visible
    modalInfo.title = props.title
    modalInfo.inputTitle = props.inputTitle
    modalInfo.text = props.text
  })
  const { visible, title, inputTitle, text } = toRefs(modalInfo)
  return { visible, title, inputTitle, text }
}

export default({
  name: 'RemarkModal',
  props: ['visible',  // 控制显示隐藏
          'title', // 模态窗标题
          'inputTitle', // 输入框标题
          'text', // 输入框内容
          'placeholder', // 输入框提示文字
          'iscallback',
  ],
  /**
   * 程序入口
   */
  setup(props, { emit }) {
    const { visible, title, inputTitle, text } = watchPropsEffect(props)
    const handleOk = () => {
      if (props.iscallback) {
        emit('finishEditText', text.value, props.iscallback)
      } else {
        emit('finishEditText', text.value)
      }
    }
    const handleClose = () => {
      emit('finishEditText', '', false, true)
    }

    return {
      visible,
      title,
      inputTitle,
      text,
      handleOk,
      handleClose,
    }
  },
})
</script>

<style lang='less'>
.titleDescModal {
  .main {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 41px;
    font-weight: 100;
    > div:nth-child(1) {
      width: 130px;
      text-align: right;
      white-space: nowrap;
    }
  }
  .ant-modal-content {
    position: relative;
    border: 16px solid #fff;
    border-radius: 16px;
    .ant-modal-header {
      border: none;
      .ant-modal-title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .ant-modal-body {
      .close {
        position: absolute;
        top: 8px;
        right: 24px;
        font-size: 25px;
        color: #00A0E9;
        .pointer()
      }
      .ant-input-affix-wrapper {
        min-height: 41px;
        border: 1px solid #d7d7d7;
        .ant-input {
          resize:none;
          line-height: 31px;
        }
        span {
          line-height: 31px;
        }
        svg {
          font-size: 18px;
        }
      }
    }
    .ant-modal-footer {
      border: none;
      div {
        text-align: center;
        button {
          height: 36px;
          width: 108px;
          font-weight: 100;
          color: #D3D3DD;
        }
        .ant-btn-primary {
          margin-left: 32px;
          color: #fff;
        }
      }
    }
  }
  .ant-modal-footer {
    border: none;
    div {
      text-align: center;
    }
  }
}
</style>